import React, { useState } from "react";

import { Row, Col, Label, Button, Spinner, FormGroup, Input } from "reactstrap";

import PageDescription from "components/PageDescription";

const Create = (props) => {
  return (
    <div className="app-main">
      <PageDescription title="Créer " />
    </div>
  );
};

export default Create;
