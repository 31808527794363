import React, { useState } from "react";

import { Col, Row, Button, Spinner } from "reactstrap";
import Snackbar from "@material-ui/core/Snackbar";
// import MuiAlert from '@material-ui/lab/Alert';
import Fade from "@material-ui/core/Fade";

import ErrorLabel, { usePasswordCheck } from "hooks/usePasswordCheck";
import PageDescription from "components/PageDescription";

import AppInput from "components/AppInput";

import { Api } from "api";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";

import LogoutModal from "components/LogoutModal";
import AuthNavbar from "components/Navbars/TopNavbar";
import TopNavbar from "components/Navbars/TopNavbar";
import { setActiveTab } from "actions";
//notifications
import { NotificationManager } from "react-notifications";

import "./passreset-styles.scss";

// function Alert(props) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// }

export default function MonEspace(props) {
  const [showLogout, setShowLogout] = useState(false);
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const [passwordActuel, setPasswordActuel] = useState("");
  const [confirm, setConfirm] = useState("");
  const [openPassword1, setOpenPassword1] = useState(false);
  const [openPassword2, setOpenPassword2] = useState(false);
  const [openPassword3, setOpenPassword3] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [confirmHasError, setConfirmError] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [displayAlert, setDisplayAlert] = useState({
    display: false,
    text: "",
    error: false,
  });
  const { isValid: passwordValid } = usePasswordCheck(password);

  const onSubmit = async () => {
    try {
      if (password !== confirm) {
        setConfirmError(true);
      } else {
        setFetching(true);
        setConfirmError(false);
        // send to BE
        await Api().post("/auth/users/password", {
          currentPassword: passwordActuel,
          newPassword: password,
          verifyPassword: confirm,
        });
        setPassword("");
        setConfirm("");
        setPasswordActuel("");
        setDisplayAlert({
          display: true,
          text: "Votre mot de passe a bien été modifié",
          error: false,
        });
        // props.history.replace("/auth");
      }
    } catch (e) {
      setDisplayAlert({
        display: false,
        text: e?.message || "Erreur de modification",
        error: true,
      });
      NotificationManager.error(
        e?.message ||
          (e?.result?.length > 0 && e.result.map((item) => item.message)),
        false,
        10000
      );
    } finally {
      setFetching(false);
    }
  };

  return (
    <>
      <div className="app-container">
        <AuthNavbar displayLogout={() => setShowLogout(true)} />
        {/* <TopNavbar /> */}

        {showLogout && (
          <LogoutModal
            isOpen={showLogout}
            onCloseModal={() => setShowLogout(false)}
          />
        )}
        <Row>
          <Col>
            <div className="title-section text-center text-bold my-4">
              Modifier le mot de passe
            </div>
          </Col>
        </Row>
        <div>
          <div className="space-page-container">
            <div className="space-step-form">
              <Row>
                <Col xs="12" sm="3">
                  <AppInput
                    label=" Mot de passe actuel "
                    value={passwordActuel}
                    onChange={(t) => setPasswordActuel(t)}
                    type={openPassword1 ? "text" : "password"}
                    leftaddon={
                      <div
                        onClick={() => setOpenPassword1(!openPassword1)}
                        className="input-addon"
                      >
                        {/* <img
                          alt="+"
                          src={
                            openPassword1
                              ? require("assets/img/eye-b.png")
                              : require("assets/img/eye.png")
                          }
                        /> */}
                      </div>
                    }
                  />
                  <div className="mt-2">
                    {passwordActuel !== "" && (
                      <ErrorLabel password={passwordActuel} />
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs="12" sm="3">
                  <AppInput
                    label="Nouveau mot de passe"
                    value={password}
                    onChange={(t) => setPassword(t)}
                    type={openPassword2 ? "text" : "password"}
                    leftaddon={
                      <div
                        onClick={() => setOpenPassword2(!openPassword2)}
                        className="input-addon"
                      >
                        {/* <img
                          alt="+"
                          src={
                            openPassword2
                              ? require("assets/img/eye-b.png")
                              : require("assets/img/eye.png")
                          }
                        /> */}
                      </div>
                    }
                  />
                  {password !== "" && <ErrorLabel password={password} />}
                </Col>
              </Row>
              <Row>
                <Col xs="12" sm="3">
                  <AppInput
                    label="Confirmer mot de passe"
                    value={confirm}
                    onChange={(t) => setConfirm(t)}
                    type={openPassword3 ? "text" : "password"}
                    leftaddon={
                      <div
                        onClick={() => setOpenPassword3(!openPassword3)}
                        className="input-addon"
                      >
                        {/* <img
                          alt="+"
                          src={
                            openPassword3
                              ? require("assets/img/eye-b.png")
                              : require("assets/img/eye.png")
                          }
                        /> */}
                      </div>
                    }
                  />
                  {confirmHasError && (
                    <h6 className="login-input-error">
                      Les mots de passes ne sont pas identiques
                    </h6>
                  )}
                  {displayAlert.error && (
                    <h6 className="login-input-error text-center">
                      {displayAlert.text}
                    </h6>
                  )}

                  {displayAlert.display && (
                    <h6 className="login-input-success text-center">
                      {displayAlert.text}
                    </h6>
                  )}
                </Col>
              </Row>

              <Row>
                <Col xs="12" sm="3">
                  <Button
                    className="btn-Global"
                    onClick={(e) => {
                      // e.preventDefault();
                      onSubmit();
                    }}
                    block
                    size="lg"
                    disabled={!passwordValid || !confirm}
                  >
                    {fetching ? (
                      <Spinner size="sm" color="light" />
                    ) : (
                      "Sauvegarder"
                    )}
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col sm="12" md="3" className="page-description-content">
                  <div
                    onClick={(e) => {
                      dispatch(push("/home/list"));
                    }}
                    className="text-bold countCategory text-center mt-2"
                    style={{ cursor: "pointer" }}
                  >
                    Retourner à l’accueil
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        {/* <Snackbar
        open={displayAlert.display}
        onClose={() =>
          setDisplayAlert({display: false, text: '', error: false})
        }
        TransitionComponent={Fade}
        key={Fade.name}>
        <Alert
          onClose={() =>
            setDisplayAlert({display: false, text: '', error: false})
          }
          severity={displayAlert.error ? 'error' : 'info'}>
          {displayAlert.text}
        </Alert>
      </Snackbar> */}
      </div>
    </>
  );
}
