import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";

// some actions
import { setSidebarOpen, setActiveTab } from "actions";

import Footer from "components/PageFooter";
import LogoutModal from "components/LogoutModal";

import breadcrumbsMap from "breadcrumbsMap";

const AppLayout = (props) => {
  const dispatch = useDispatch();

  const [breadCrumbs, setBreadCrumbs] = useState(null);
  const [showLogout, setShowLogout] = useState(false);

  useEffect(() => {
    const brd = breadcrumbsMap.find((one) => one?.path === props.path);
    setBreadCrumbs(brd);
  }, [props.path]);

  return (
    <>
      <div className="app-layout">{props.children}</div>
      <Footer />
      {showLogout && (
        <LogoutModal
          isOpen={showLogout}
          onCloseModal={() => setShowLogout(false)}
        />
      )}
    </>
  );
};

export default AppLayout;
