// import front-end config
import { REHYDRATE } from "redux-persist/es/constants";
import {
  SET_CURRENT_USER,
  LOGOUT_S,
  SET_CURRENT_LANGUAGE,
  SET_MODE_CATEGORIE,
  SET_MODE_BLACK_LIST,
  SET_QUESTIONS,
  SET_QUIZZE,
  SET_ELEMENT_OF_RANK,
  VIDER_STATE,
} from "../actionsTypes";

/**
 * ## InitialState
 *
 * The fields we're concerned with
 */
const initialState = {
  currentUser: null,
  modeCategorie: false,
  blackList: [],
  quizze: {},
  questions: [],
  elementOfRank: 1,
  currentLanguage: {
    label: "English",
    value: "english",
    iconUrl: require("assets/img/iconEn.png"),
  },
};

/**
 * ## Reducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function globalReducer(state = initialState, action) {
  switch (action.type) {
    case REHYDRATE:
      if (action.payload) {
        return {
          ...state,
          sidebarIsOpen: true,
          blackList: action.payload.app?.blackList || [],
          modeCategorie: action.payload.app?.modeCategorie || false,
          quizze: action.payload.app?.quizze || {},
          questions: action.payload.app?.questions || [],
          elementOfRank: action.payload.app?.elementOfRank || 1,
          currentLanguage: action.payload.app?.currentLanguage || [],
        };
      }
      return state;

    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
      };

    case SET_CURRENT_LANGUAGE:
      return {
        ...state,
        currentLanguage: action.payload,
      };
    case SET_MODE_CATEGORIE:
      return {
        ...state,
        modeCategorie: action.payload,
      };

    case SET_QUESTIONS:
      return {
        ...state,
        questions: action.payload,
      };
    case SET_ELEMENT_OF_RANK:
      return {
        ...state,
        elementOfRank: action.payload,
      };

    case SET_QUIZZE:
      return {
        ...state,
        quizze: action.payload,
      };

    case SET_MODE_BLACK_LIST:
      return {
        ...state,
        blackList: action.payload,
      };
    case VIDER_STATE:
      return {
        ...state,
        quizze: {},
        questions: [],
        elementOfRank: 1,
      };
    case LOGOUT_S:
      return initialState;

    default:
      return state;
  }
}
