import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

/*
 * Import reducers
 */
import app from "./appReducer";
import auth from "../modules/Auth/reducers";
import home from "../modules/Home/reducers";

const createReducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    app,
    auth,
    home,
  });

export default createReducers;
