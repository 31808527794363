// Api
import { Api } from "api";

import reportError from "lib/errorHandler";

// some endpoints
import {
  meUrl,
  uploadUrl,
  uploadReferencesUrl,
  uploadFournisseurUrl,
} from "api/endpoints";

import {
  SET_CURRENT_USER,
  SET_CURRENT_LANGUAGE,
  LOGOUT_S,
  SET_MODE_CATEGORIE,
  SET_MODE_BLACK_LIST,
  SET_QUESTIONS,
  SET_QUIZZE,
  SET_ELEMENT_OF_RANK,
  VIDER_STATE,
} from "actionsTypes";

export const logoutSuccess = () => ({
  type: LOGOUT_S,
});

export const setCurrentUser = (payload) => ({
  type: SET_CURRENT_USER,
  payload,
});

export const setCurrentLanguage = (payload) => ({
  type: SET_CURRENT_LANGUAGE,
  payload,
});

export const setModeCategorie = (payload) => ({
  type: SET_MODE_CATEGORIE,
  payload,
});
export const setQuestions = (payload) => ({
  type: SET_QUESTIONS,
  payload,
});
export const setElementOfRank = (payload) => ({
  type: SET_ELEMENT_OF_RANK,
  payload,
});

export const setQuizz = (payload) => ({
  type: SET_QUIZZE,
  payload,
});
export const setBlackList = (payload) => ({
  type: SET_MODE_BLACK_LIST,
  payload,
});

export const viderState = (payload) => ({
  type: VIDER_STATE,
  payload,
});

export const getMe = () => {
  return (dispatch) => {
    Api()
      .get(meUrl())
      .then((data) => {
        if (data) {
          dispatch(setCurrentUser(data));
        } else {
        }
      })
      .catch((err) => {
        reportError("get me error", err);
      });
  };
};

export const uploadFiles = (payload) => {
  const promise = new Promise(async (resolve, reject) => {
    try {
      const data = await Api().post(uploadUrl(), payload, true);
      resolve(data);
    } catch (err) {
      reportError("upload files error ", err);
      reject(err);
    }
  });

  return promise;
};

export const uploadFileReferences = (payload) => {
  const promise = new Promise(async (resolve, reject) => {
    try {
      const data = await Api().post(uploadReferencesUrl(), payload, true);
      resolve(data);
    } catch (err) {
      reportError("upload files error ", err);
      reject(err);
    }
  });

  return promise;
};
export const uploadFileFournisseur = (payload) => {
  const promise = new Promise(async (resolve, reject) => {
    try {
      const data = await Api().post(uploadFournisseurUrl(), payload, true);
      resolve(data);
    } catch (err) {
      reportError("upload files error ", err);
      reject(err);
    }
  });

  return promise;
};
