import React, { useEffect } from "react";
import { Route, Switch } from "react-router";
import routes from "./routes";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { currentBlackList } from "modules/Home/selector";
import { setBlackList } from "actions";

function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      render={(props) => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} />
      )}
    />
  );
}

function App() {
  // Remove category exceed 24h
  const blackList = useSelector(currentBlackList) || [];
  const dispatch = useDispatch();

  useEffect(() => {
    const exceedCategory = () => {
      const newBlackList = [];

      blackList.length > 0 &&
        blackList.map((item) => {
          console.log(
            moment
              .duration(new Date() - new Date(item.date), "milliseconds")
              .minutes()
          );
          if (
            moment
              .duration(new Date() - new Date(item.date), "milliseconds")
              .minutes() > 1
          ) {
            newBlackList.push(false);
          } else {
            newBlackList.push(item);
          }
        });
      dispatch(setBlackList(newBlackList.filter((item) => item !== false)));
    };
    exceedCategory();
  }, []);

  return (
    <Switch>
      {routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))}
    </Switch>
  );
}

export default App;
