export function meUrl() {
  return "/me";
}

export function logoutUrl() {
  return "/auth/users/signout";
}

export function uploadUrl() {
  return "/files";
}
export function uploadReferencesUrl() {
  return "/admin/references/bulk";
}
export function uploadFournisseurUrl() {
  return "/admin/fournisseurs/bulk";
}
