import React, { useState, useEffect } from "react";

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { LIST_LANGUAGE } from "config/app";
import Switch from "react-switch";
import { useDispatch } from "react-redux";
import { setCurrentLanguage, setModeCategorie } from "actions";
import { push } from "connected-react-router";
import { viderState } from "actions";

function TopNavbar(props) {
  const dispatch = useDispatch();

  const [checked, setChecked] = useState(props.modeCategorie || false);
  const handleChange = (nextChecked) => {
    dispatch(setModeCategorie(nextChecked));
  };

  useEffect(() => {
    if (props.modeCategorie) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [props.modeCategorie]);

  return (
    <div className="TopNavbar">
      <div className="appLogo">
        <img
          onClick={() => {
            dispatch(push("/home/list"));
            dispatch(viderState());
          }}
          width={"190px"}
          alt=".."
          src={require("assets/img/logo.png")}
        />
      </div>

      <div className="contentHeaderRight">
        {!props.hideMenu && (
          <>
            <div className="mx-4 switchHeader">
              <Switch
                onChange={handleChange}
                checked={checked}
                className="react-switch"
                offColor="#616775"
                onColor="#04c011"
                height={30}
                width={115}
                uncheckedIcon={<div className="text-switch-off">Hard mode</div>}
                checkedIcon={<div className="text-switch-on">Easy mode</div>}
              />
            </div>
          </>
        )}
        <div className="menuHeader">
          <UncontrolledDropdown setActiveFromChild>
            <DropdownToggle className="nav-link" tag="a">
              <img
                className="iconMenu"
                width={"45px"}
                alt=".."
                src={require("assets/img/iconMenu.png")}
              />
            </DropdownToggle>
            <DropdownMenu>
              {/* {LIST_LANGUAGE.map((item) => (
              <DropdownItem
                onClick={() => dispatch(setCurrentLanguage(item))}
                href="#"
                tag="a"
              >
                {item.label}
              </DropdownItem>
            ))} */}
              {/* <DropdownItem href="#" tag="a" className="mr-4 ">
               <img
                width={"30px"}
                // className="iconSelect"
                alt=".."
                src={require("assets/img/iconStatistique.png")}
              /> 
              <span className=" mx-2 text-bold">Home</span>
            </DropdownItem>*/}
              {/* <DropdownItem href="#" tag="a" className="mr-4 ">
               <img
                width={"30px"}
                // className="iconSelect"
                alt=".."
                src={require("assets/img/iconStatistique.png")}
              /> 
              <span className=" mx-2 text-bold">Stats</span>
            </DropdownItem> */}
              {/* <DropdownItem href="#" tag="a" className="mr-4 ">
               <img
                width={"30px"}
                // className="iconSelect"
                alt=".."
                src={require("assets/img/iconStatistique.png")}
              /> 
              <span className=" mx-2 text-bold">Settings</span>
            </DropdownItem> */}
              {!props.hideMenu && (
                <DropdownItem href="#" tag="a" className="mr-4 ">
                  <Switch
                    onChange={handleChange}
                    checked={checked}
                    className="react-switch"
                    offColor="#616775"
                    onColor="#04c011"
                    height={30}
                    width={115}
                    uncheckedIcon={
                      <div className="text-switch-off">Hard mode</div>
                    }
                    checkedIcon={
                      <div className="text-switch-on">Easy mode</div>
                    }
                  />
                </DropdownItem>
              )}

              {LIST_LANGUAGE.map((item, i) => (
                <DropdownItem
                  key={i}
                  onClick={() => {
                    dispatch(viderState());
                    dispatch(setCurrentLanguage(item));
                  }}
                  href="#"
                  tag="a"
                >
                  {item.label}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <div className="SelectLanguage">
          <UncontrolledDropdown setActiveFromChild>
            <DropdownToggle className="nav-link navLinkLanguage" tag="a">
              {/* <img width={"30px"} alt=".." src={props.currentLanguage?.iconUrl} /> */}
              <span className="currentLanguage text-demi">
                {props.currentLanguage?.label}
              </span>
              <img
                className="iconSelect"
                alt=".."
                src={require("assets/img/iconSelect.png")}
              />
            </DropdownToggle>
            <DropdownMenu>
              {LIST_LANGUAGE.map((item, i) => (
                <DropdownItem
                  key={i}
                  onClick={() => {
                    dispatch(viderState());
                    dispatch(setCurrentLanguage(item));
                  }}
                  href="#"
                  tag="a"
                >
                  {item.label}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
    </div>
  );
}
export default TopNavbar;
