export function CategorieUrl(params, mode) {
  const modeSelected = mode ? "easy" : "hard";
  return (
    params &&
    `/user/categories/?mode=${modeSelected}&$sort={ "updated_at": "DESC"}${params}`
  );
}

export function quizzeByCategorieUrl(categoryId, params) {
  return categoryId && `/user/categories/${categoryId}/quizzes/`;
}

export function questionByQuizzeUrl(questionId, params) {
  return questionId && `/user/quizzes/${questionId}/question/${params}`;
}
