import React from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  GabShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
  InstagramShareButton,
} from "react-share";

const Footer = () => {
  return (
    <footer className="appFooter">
      <div>
        <div>
          <div className="d-flex justify-content-center">
            <div className="mr-1">
              <img
                width="30px"
                alt=".."
                src={require("assets/img/arrowFooter.png")}
              />
            </div>
            <div>
              <div className="titleFooter">Share KATCCHI with friends</div>
              <div className="d-flex align-items-center justify-content-space-between iconShare">
                <FacebookShareButton url="https://www.katcchi.com">
                  <img
                    width="43px"
                    alt=".."
                    src={require("assets/img/fb-icon.png")}
                  />
                </FacebookShareButton>
                {/*
                Instagram does not support sharing functionality for the web at this point
                https://stackoverflow.com/questions/69100154/share-to-instagram-feed-using-javascript
                 */}
                <div>
                  <img
                    className="mx-3"
                    width="49px"
                    alt=".."
                    src={require("assets/img/insta-icon.png")}
                  />
                </div>
                <TwitterShareButton url="https://www.katcchi.com">
                  <img
                    width="40px"
                    alt=".."
                    src={require("assets/img/x-icon.png")}
                  />
                </TwitterShareButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="mr-2">
          <img
            width="45px"
            alt=".."
            src={require("assets/img/footerLogo.png")}
          />
        </div>
        <div>
          <div className="textFooter"> © 2024 KATCCHI. All rights reserved</div>
          <div>
            <a className="mr-3 linkFooter" href="#" target="_blank">
              Terms
            </a>
            |
            <a className="mx-3 linkFooter" href="#" target="_blank">
              Privacy
            </a>
            |
            <a className=" ml-3 linkFooter" href="#" target="_blank">
              Cookies
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
