export const GOOGLE_API_KEY = "AIzaSyBXWOxwBiZNJf_cTQ3NwieZfZhJrH4lPJg";

export const LIST_CIVILITY = [
  { label: "Monsieur", value: "M" },
  { label: "Madame", value: "Mme" },
  { label: "Mademoiselle", value: "Mlle" },
];

export const POSTS_TRAVEAUX = [
  { label: "Jours", value: "Jours" },
  { label: "Nuits", value: "Nuits" },
  { label: "Weekends", value: "Weekends" },
];

export const LIST_LANGUAGE = [
  {
    label: "English",
    value: "english",
    iconUrl: require("assets/img/iconEn.png"),
  },
  {
    label: "Spanish",
    value: "espanyol",
    iconUrl: require("assets/img/iconSpa.png"),
  },
  {
    label: "French",
    value: "french",
    iconUrl: require("assets/img/iconFr.png"),
  },
];

export const LIST_MODE_QUESTION = [
  { label: "Easy", value: "easy" },
  { label: "Hard", value: "hard" },
];
