import React, { useEffect, useState, useCallback } from "react";

import { FormGroup, Label, Input } from "reactstrap";
import { components } from "react-select";
import Select from "react-select";

import Api from "api/api";
import { debounce } from "lodash";

const customStyles = {
  container: (provided) => ({
    ...provided,
    backgroundColor: "#ffff",
    borderRadius: "25px",
    borderWidth: "1px",
    color: "#18394B",
    borderColor: "#04485e",
    height: "44px",
    width: "100%",
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: "#ffff",
    borderRadius: "25px",
    borderWidth: "1px",
    borderColor: "#04485e",
    height: "44px",
    width: "100%",
    color: "#18394B",
    fontSize: "1em",
    // This line disable the blue border
    boxShadow: "none",
    outline: "none",
    "&:hover": {
      borderColor: "#04485e",
    },
  }),
};

const SelectInput = ({
  type,
  url,
  label,
  onSelectValue,
  onInputChange,
  SelectDefaulteValue,
  valueFilter,
  value,
  defaultValue,
  required,
  name,
  placeholder,
  disabled,
  inputSearch,
  isClear,
  autoFocus,
  openMenuOnFocus,
  id,
  ref,
  className,
  isMulti,
  styles,
  filterQuery,
  characterLength,
  timerHandlerList,
  isClearable,
  withoutFilter,
  isActiveClear,
  elementsToFilter, //used in create synthese to filter duplicated agents types
  ...props
}) => {
  const [options, setOption] = useState([]);
  const [loading, setLoading] = useState(false);
  const [icanClear, setIcanClear] = useState(true);
  const [formattedOptions, setFormattedOptions] = useState([]);

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(new Date());
  };

  const handleBlur = () => {
    setMenuIsOpen(false);
  };
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const handleMenuOpen = () => {
    setMenuIsOpen(true);
  };

  const handleMenuClose = () => {
    setMenuIsOpen(false);
  };

  const loadOptions = async (value) => {
    setLoading(true);
    try {
      const apiUrl = `${url}&$q=${value || ""}&$top=70`;

      const data = await Api().get(apiUrl);

      const filterData = !withoutFilter
        ? data.value.filter(
            (item) =>
              item.name !== "planning non validé" &&
              item.name !== "planning validé" &&
              item.name !== "planning pas payé"
          )
        : data.value;

      // setEmployers(data.value);
      const formatted = filterData.map((one) => ({
        value: one._id,
        country: one?.country,
        data: one,
        reference: type === "product" ? `${one.reference}` : "",
        codeNaf: one.code,
        codeNafActivite: one.activite,
        isDisabled:
          props.products?.length > 0
            ? props.products.some((item) => item.id === one.id)
            : one.is_user
            ? !one.accountEnabled
            : false,
        label:
          type === "quizz"
            ? one.answer
            : type === "syntheses"
            ? `${one.code_str}-${one.site?.name} `
            : type === "sites"
            ? `${one.numero || ""}-${one.name} `
            : type === "languge"
            ? `${one.nameEng} / ${one.nameFr} / ${one.nameIt} / ${one.nameGr} `
            : type === "affchName"
            ? one.affchName
            : type === "name"
            ? one.name
            : type === "email"
            ? one.email
            : type === "code"
            ? one.code
            : type === "codeStr"
            ? one.codeStr
            : type === "messagetemplates"
            ? one.title
            : type === "nameComplet"
            ? `${one.name.first} ${one.name.last}`
            : type === "Fournisseur"
            ? `${one.codeStr ? one.codeStr : ""}/ ${one.name}`
            : type === "product"
            ? `${one.reference} / ${one.name}`
            : type === "naf"
            ? `${one.code} / ${one.activite}`
            : type === "client"
            ? `${one.codeStr} / ${
                one.socialReason === "N/A"
                  ? `${one.name.first} ${one.name.last}`
                  : `${one.socialReason}`
              }`
            : "",
      }));

      if (formatted?.length > 0 && elementsToFilter?.length > 0) {
        let arr = [];
        formatted.map((elm) => {
          if (!elementsToFilter.find((item) => elm?.value === item)) {
            arr = [...arr, elm];
          }
        });

        setOption(arr);
        setLoading(false);
      } else {
        setOption(formatted);
        if (SelectDefaulteValue && formatted?.length === 1 && icanClear) {
          onSelectValue(formatted[0]);
          setIcanClear(false);
        }
      }
      setFormattedOptions(formatted);
      setLoading(false);
    } catch {
      setOption([]);
      setFormattedOptions([]);
      setLoading(false);
    }
  };

  const handler = useCallback(
    debounce((search) => {
      if (url && isFocused && search) {
        setOption([]);
        loadOptions(search);
      }
    }, timerHandlerList || 1000),
    [filterQuery, isFocused]
  );

  useEffect(() => {
    if (formattedOptions?.length > 0 && elementsToFilter?.length > 0) {
      let arr = [];
      formattedOptions.map((elm) => {
        if (!elementsToFilter.find((item) => elm?.value === item)) {
          arr = [...arr, elm];
        }
      });

      setOption(arr);
    }
  }, [elementsToFilter]);

  useEffect(() => {
    if (url && SelectDefaulteValue) {
      setOption([]);
      loadOptions();
    }
  }, [props.products, url, filterQuery, SelectDefaulteValue]);

  useEffect(() => {
    if (url && isFocused) {
      setOption([]);
      loadOptions();
    }
  }, [props.products, url, filterQuery, isFocused]);

  const Placeholder = (props) => {
    return <components.Placeholder {...props} />;
  };

  const CaretDownIcon = () => {
    return props.leftAddon ? (
      props.leftAddon
    ) : (
      <div className={props?.classNameAddOn || "input-addon"}>
        <img width="20px" alt="+" src={require("assets/img/iconSelect.png")} />
      </div>
    );
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };

  const MultiValueLabel = (props) => {
    // Customize the label component if needed
    return <components.MultiValueLabel {...props} />;
  };

  const MultiValueRemove = (props) => {
    // If the option is fixed, don't render the remove component
    if (props.data.isFixed) {
      return null;
    }
    return <components.MultiValueRemove {...props} />;
  };

  return !disabled ? (
    <FormGroup>
      {label && (
        <Label className="text-black text-regular" htmlFor={name}>
          <span className="text-black  custom-label">
            {label}
            {required && <span> *</span>}
          </span>
        </Label>
      )}
      <Select
        key={id}
        menuIsOpen={menuIsOpen && isMulti}
        onMenuOpen={handleMenuOpen}
        // onMenuClose={handleMenuClose}
        // isLoading={loading}
        onFocus={handleFocus}
        onBlur={handleBlur}
        styles={styles ? styles : customStyles}
        className={className}
        placeholder={placeholder}
        isMulti={isMulti}
        isSearchable={true}
        filterOption={() => true} // Disable local filtering
        isClearable={isClearable !== undefined ? isClearable : true}
        isOptionDisabled={(option) => option.isDisabled}
        options={options}
        isDisabled={disabled}
        autoFocus={autoFocus}
        autosize={true}
        openMenuOnFocus={openMenuOnFocus}
        ref={ref}
        // onInputChange={(e) => {
        //     onSelectValue(e)
        // }}
        onInputChange={(e) => {
          // onInputChange(e);

          handler(e);
        }}
        onChange={(select) => onSelectValue(select)}
        id={id}
        value={value !== undefined ? value : null}
        defaultValue={defaultValue}
        components={{
          Placeholder,
          DropdownIndicator,
          MultiValueLabel,
          MultiValueRemove,
        }}
        // defaultValue={props.defaultValue}
      />

      {value && isActiveClear && (
        <div
          onClick={() => {
            isClear();
          }}
          className="iconClean"
        ></div>
      )}
    </FormGroup>
  ) : (
    <FormGroup>
      {label && (
        <Label className="text-black text-regular" htmlFor={name}>
          {/* {label}
        {required && <span> *</span>} */}

          <span className="text-black  custom-label">
            {label}
            {required && <span> *</span>}
          </span>
        </Label>
      )}
      <Select
        // defaultMenuIsOpen
        styles={styles ? styles : customStyles}
        className={className}
        placeholder={placeholder}
        isMulti={isMulti}
        isSearchable={true}
        isClearable={false}
        options={options}
        isDisabled={true}
        autoFocus={autoFocus}
        openMenuOnFocus={openMenuOnFocus}
        ref={ref}
        // onInputChange={(e) => {
        //     onSelectValue(e)
        // }}
        onInputChange={(e) => {
          // onInputChange(e);

          handler(e);
        }}
        //onChange={(select) => onSelectValue(select)}
        // id={id}
        value={value}
        defaultValue={defaultValue}
        components={{ Placeholder, DropdownIndicator }}
        // defaultValue={props.defaultValue}
      />
      {props.leftaddon && props.leftaddon}
    </FormGroup>
  );
};

export default SelectInput;
