import React, { useState, useEffect } from "react";

import { Input, Button, Row, Col, Spinner } from "reactstrap";

import useFetchList from "hooks/useFetchList";

import { CategorieUrl } from "../../api/endpoints";
import ItemCategorie from "../../components/ItemCategorie";

import "assets/css/listviews.css";
import CONFIG from "config/apiConfig";

import { Api } from "api";

import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

import AuthNavbar from "components/Navbars/TopNavbar";
import { quizzeByCategorieUrl, questionByQuizzeUrl } from "../../api/endpoints";
import { NotificationManager } from "react-notifications";

const List = (props) => {
  const { BASE_URL } = CONFIG;

  const [top, setTop] = useState(15);
  const [apiEndpoint, setEndpoint] = useState(CategorieUrl(""));
  const { data, count, fetching } = useFetchList(apiEndpoint);
  const [newListCategories, setNewListCategories] = useState([]);

  // dispatch actions
  const dispatch = useDispatch();
  useEffect(() => {
    const filter = { language: props.currentLanguage?.value };
    console.log({ filter });
    const urlQuery = `${`&$top=${top}`}${
      filter ? "&$filter=" + JSON.stringify(filter) : "&$filter=" + ""
    }`;
    setEndpoint(CategorieUrl(urlQuery, props.modeCategorie));
  }, [props.currentLanguage, props.modeCategorie, top]);

  useEffect(() => {
    const ConvertData = () => {
      const NewData = [];
      data.length > 0 &&
        data.map((item) => {
          if (props.blackList.some((cat) => cat.id === item.id)) {
            NewData.push({ ...item, withblacklist: true });
          } else {
            NewData.push({ ...item, withblacklist: false });
          }
        });
      setNewListCategories(NewData);
    };
    ConvertData();
  }, [data, props.blackList]);

  //set Quizze and question Selected
  const [fetchingCat, setFetchingCat] = useState(false);

  const selectCategory = async (idCategory) => {
    const filter = {
      mode: props.modeCategorie ? "easy" : "hard",
    };
    const urlQuery = `${
      filter ? "?&$filter=" + JSON.stringify(filter) : "&$filter=" + ""
    }&$top="10000"`;
    try {
      setFetchingCat(true);
      const dataQuizz = await Api().get(
        quizzeByCategorieUrl(idCategory, urlQuery)
      );
      const dataQuestion = await Api().get(
        questionByQuizzeUrl(dataQuizz?._id, urlQuery)
      );
      props.actions.setQuizz(dataQuizz);
      props.actions.setQuestions(dataQuestion?.value || []);
      setFetchingCat(false);
      dispatch(push("/home/quizze"));
    } catch (err) {
      // maybe display an error message
      setFetchingCat(false);
      NotificationManager.error(
        err?.message ||
          (err?.result?.length > 0 && err.result.map((item) => item.message)),
        false,
        10000
      );
      console.log(err);
    }
  };

  // Load more orders
  const loadMoreOrders = () => {
    setTop((prevTop) => prevTop + 10);
  };

  return (
    <div className="app-main">
      <AuthNavbar
        currentLanguage={props.currentLanguage}
        modeCategorie={props.modeCategorie}
        // displayLogout={() => setShowLogout(true)}
      />

      <div className="slider-home-desktop">
        <div>
          <img
            width={"290px"}
            alt=".."
            src={require("assets/img/iconSlide.png")}
          />
        </div>
        <div>
          <div className="bigTitleSlider">Knowledge & fun </div>
          <div className="smallTitleSlider">All in one</div>
        </div>
      </div>

      <div className="slider-home-mobile">
        <div>
          <div className="bigTitleSlider">Knowledge & fun </div>
          <div className="smallTitleSlider">All in one</div>
        </div>
        <div>
          <img
            width={"220px"}
            alt=".."
            src={require("assets/img/iconSlide.png")}
          />
        </div>
      </div>

      <div
        className="backgroundImageGlobal"
        style={{
          backgroundImage: `url(${require("assets/img/bgImage.png")})`,
        }}
      ></div>
      <div className="containerHome mt-5">
        <div className="bigTitleHome">Enjoy an ever expanding library</div>
        <div className="smallTitleHome"> of curated trivia questions</div>
        <Row className="containerCategorie">
          {newListCategories?.length > 0 &&
            newListCategories.map((item, i) => (
              <div key={i}>
                <ItemCategorie
                  // setToBlackList={() => setToBlackList(item.id)}
                  setCurrentCategory={() => {
                    props.actions.setCurrentCategory(item);
                    selectCategory(item.id);
                  }}
                  title={item.name}
                  withblacklist={item.withblacklist}
                  description={item.description}
                  url={`${BASE_URL}/api/v1/files/${item?.image}/view`}
                  fetchingCat={fetchingCat}
                />
              </div>
            ))}
        </Row>
        {/* Render load more button */}
        {newListCategories.length > 9 && newListCategories.length !== count && (
          <div className=" btn-charger-plus" onClick={loadMoreOrders}>
            {fetching ? (
              <Spinner size="sm" />
            ) : (
              <span className="eb-text-bold">More quizzes</span>
            )}
          </div>
        )}{" "}
      </div>
    </div>
  );
};

export default List;
