import Settings from "modules/Settings";
import PasswordReset from "modules/PasswordReset";
import Home from "modules/Home";

const routes = [
  { path: "/settings", component: Settings },
  { path: "/home", component: Home },
  { path: "/passwordreset", component: PasswordReset },
];

export default routes;
