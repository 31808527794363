import React, { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { ThemeContext } from "contextProviders/ThemeProvider";
import * as actions from "../../actions";
import * as globalActions from "actions";
import {
  detailsStateSelect,
  currentUserStateSelect,
  currentLanguageSelect,
  currentModeCategorie,
  currentQuestions,
  currentQuizze,
  currentElementOfRank,
  currentBlackList,
} from "../../selector";
import DetailsAdmins from "./component";

const DetailsAdminsContainer = (props) => {
  const theme = useContext(ThemeContext);
  const { current } = useSelector(detailsStateSelect);
  const { roles } = useSelector(currentUserStateSelect) || {};
  const currentLanguage = useSelector(currentLanguageSelect) || {};
  const modeCategorie = useSelector(currentModeCategorie) || false;
  const questions = useSelector(currentQuestions) || [];
  const quizze = useSelector(currentQuizze) || {};
  const elementOfRank = useSelector(currentElementOfRank) || {};
  const blackList = useSelector(currentBlackList) || {};

  const dispatch = useDispatch();
  const actionsDispatch = bindActionCreators(
    { ...actions, ...globalActions },
    dispatch
  );

  return (
    <DetailsAdmins
      userRole={roles?.[0]}
      theme={theme}
      currentLanguage={currentLanguage}
      modeCategorie={modeCategorie}
      questions={questions}
      elementOfRank={elementOfRank}
      quizze={quizze}
      details={current}
      blackList={blackList}
      actions={actionsDispatch}
      {...props}
    />
  );
};

export default DetailsAdminsContainer;
