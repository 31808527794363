import { useState } from "react";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

// helpers
import reportError from "lib/errorHandler";

// api
import { Api } from "api";

// actions
import { uploadFiles } from "actions";

// endpoints
// import { quotesByIdUrl } from "../api/endpoints";
//notifications
import { NotificationManager } from "react-notifications";

export function useUpdateQuizze(company) {
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  async function updateQuizze(role, payload, uploadPayload) {
    try {
      setFetching(true);
      let logoData;
      if (uploadPayload) {
        logoData = await uploadFiles(uploadPayload);
      }
      // await Api().put(quotesByIdUrl(role, company), {
      //   ...payload,
      //   ...(logoData && { image: logoData.file }),
      // });
      dispatch(push("/Quizze/list"));
    } catch (error) {
      reportError("Quizze error", error);
      NotificationManager.error(
        error?.message ||
          (error?.result?.length > 0 &&
            error.result.map((item) => item.message)),
        false,
        10000
      );
      setError(error);
      setFetching(false);
    } finally {
      //   setFetching(false);
    }
  }

  return [{ fetching, error }, updateQuizze];
}
