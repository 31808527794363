import React, { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { ThemeContext } from "contextProviders/ThemeProvider";

import * as actions from "../../actions";
import * as globalActions from "actions";
import {
  listStateSelect,
  currentUserStateSelect,
  currentLanguageSelect,
  currentModeCategorie,
  currentBlackList,
} from "../../selector";

import List from "./component";

const ListContainer = (props) => {
  const theme = useContext(ThemeContext);
  const list = useSelector(listStateSelect);
  const { roles } = useSelector(currentUserStateSelect) || {};
  const currentLanguage = useSelector(currentLanguageSelect) || {};
  const modeCategorie = useSelector(currentModeCategorie) || false;
  const blackList = useSelector(currentBlackList) || [];

  const dispatch = useDispatch();
  const actionsDispatch = bindActionCreators(
    { ...actions, ...globalActions },
    dispatch
  );
  return (
    <List
      userRole={roles?.[0]}
      theme={theme}
      list={list}
      currentLanguage={currentLanguage}
      modeCategorie={modeCategorie}
      blackList={blackList}
      actions={actionsDispatch}
      {...props}
    />
  );
};

export default ListContainer;
