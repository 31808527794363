import React from "react";
import { Spinner } from "reactstrap";
import { viderState } from "actions";
import { useDispatch } from "react-redux";
function ItemCategorie(props) {
  const dispatch = useDispatch();
  return (
    <div className="cardCategorie mx-2 mt-4">
      <div className="contentImage">
        {props.url && (
          <img
            // width={"70px"}
            // className="iconSelect"
            alt=".."
            src={props.url}
          />
        )}
      </div>
      <div className="textCategorie">
        <div className="titleCategorie mt-4">
          {props.title.split("").length > 16
            ? `${props.title.slice(0, 16)}...`
            : props.title || ""}
        </div>

        <div className="descCategorie">
          {" "}
          {props.description.split("").length > 30
            ? `${props.description.slice(0, 30)}...`
            : props.description || ""}
        </div>
        {props.withblacklist ? (
          <div className="btnComeBack">Come back tomorrow</div>
        ) : (
          <div
            onClick={() => {
              // props.setToBlackList();
              if (props.fetchingCat) {
                return null;
              } else {
                dispatch(viderState());
                props.setCurrentCategory();
              }
            }}
            className="btnPlayCategorie"
          >
            Play now
          </div>
        )}
      </div>
    </div>
  );
}

export default ItemCategorie;
