import React from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

const brdMap = [
  {
    path: "/settings/themes",
    display: true,
    component: ({ theme }) => (
      <Breadcrumb>
        <BreadcrumbItem>
          <a className={`app-text-${theme}`} href="#/settings/products/list">
            Paramètres
          </a>
        </BreadcrumbItem>
        <BreadcrumbItem active>Thème</BreadcrumbItem>
      </Breadcrumb>
    ),
  },
  ,
  {
    path: "/acteurs/fournisseur/list",
    display: true,
    component: ({ theme }) => (
      <Breadcrumb>
        <BreadcrumbItem active>Fournisseur</BreadcrumbItem>
      </Breadcrumb>
    ),
  },
  {
    path: "/acteurs/fournisseur/details",
    display: true,
    component: ({ theme }) => (
      <Breadcrumb>
        <BreadcrumbItem>
          <a className={`app-text-${theme}`} href="#/acteurs/fournisseur/list">
            Fournisseur
          </a>
        </BreadcrumbItem>
        <BreadcrumbItem active>Détail Fournisseur</BreadcrumbItem>
      </Breadcrumb>
    ),
  },
  {
    path: "/acteurs/fournisseur/create",
    display: true,
    component: ({ theme }) => (
      <Breadcrumb>
        <BreadcrumbItem>
          <a className={`app-text-${theme}`} href="#/acteurs/fournisseur/list">
            Fournisseur
          </a>
        </BreadcrumbItem>
        <BreadcrumbItem active>Nouveau Fournisseur</BreadcrumbItem>
      </Breadcrumb>
    ),
  },

  ////////////////

  {
    path: "/acteurs/clients/list",
    display: true,
    component: ({ theme }) => (
      <Breadcrumb>
        <BreadcrumbItem active>Clients</BreadcrumbItem>
      </Breadcrumb>
    ),
  },
  {
    path: "/acteurs/clients/details",
    display: true,
    component: ({ theme }) => (
      <Breadcrumb>
        <BreadcrumbItem>
          <a className={`app-text-${theme}`} href="#/acteurs/clients/list">
            Clients
          </a>
        </BreadcrumbItem>
        <BreadcrumbItem active>Détail Clients</BreadcrumbItem>
      </Breadcrumb>
    ),
  },
  {
    path: "/acteurs/clients/create",
    display: true,
    component: ({ theme }) => (
      <Breadcrumb>
        <BreadcrumbItem>
          <a className={`app-text-${theme}`} href="#/acteurs/clients/list">
            Clients
          </a>
        </BreadcrumbItem>
        <BreadcrumbItem active>Nouvelle client</BreadcrumbItem>
      </Breadcrumb>
    ),
  },
  ////////////////

  {
    path: "/references/list",
    display: true,
    component: ({ theme }) => (
      <Breadcrumb>
        <BreadcrumbItem active>Références</BreadcrumbItem>
      </Breadcrumb>
    ),
  },
  {
    path: "/references/details",
    display: true,
    component: ({ theme }) => (
      <Breadcrumb>
        <BreadcrumbItem>
          <a className={`app-text-${theme}`} href="#/references/list">
            Références
          </a>
        </BreadcrumbItem>
        <BreadcrumbItem active>Détail Références</BreadcrumbItem>
      </Breadcrumb>
    ),
  },
  {
    path: "/references/create",
    display: true,
    component: ({ theme }) => (
      <Breadcrumb>
        <BreadcrumbItem>
          <a className={`app-text-${theme}`} href="#/references/list">
            Références
          </a>
        </BreadcrumbItem>
        <BreadcrumbItem active>Nouvelle Référence</BreadcrumbItem>
      </Breadcrumb>
    ),
  },
  {
    path: "/references/importReference",
    display: true,
    component: ({ theme }) => (
      <Breadcrumb>
        <BreadcrumbItem>
          <a className={`app-text-${theme}`} href="#/references/list">
            Références
          </a>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <a className={`app-text-${theme}`} href="#/references/create">
            Nouvelle Référence{" "}
          </a>
        </BreadcrumbItem>
        <BreadcrumbItem active>Importer de nouvelles Références</BreadcrumbItem>
      </Breadcrumb>
    ),
  },
  ////////////////

  {
    path: "/demandes/list",
    display: true,
    component: ({ theme }) => (
      <Breadcrumb>
        <BreadcrumbItem active>Demandes</BreadcrumbItem>
      </Breadcrumb>
    ),
  },
  {
    path: "/demandes/details",
    display: true,
    component: ({ theme }) => (
      <Breadcrumb>
        <BreadcrumbItem>
          <a className={`app-text-${theme}`} href="#/demandes/list">
            Demandes
          </a>
        </BreadcrumbItem>
        <BreadcrumbItem active>Détail Demandes</BreadcrumbItem>
      </Breadcrumb>
    ),
  },
  {
    path: "/demandes/create",
    display: true,
    component: ({ theme }) => (
      <Breadcrumb>
        <BreadcrumbItem>
          <a className={`app-text-${theme}`} href="#/demandes/list">
            Demandes
          </a>
        </BreadcrumbItem>
        <BreadcrumbItem active>Nouvelle Demande</BreadcrumbItem>
      </Breadcrumb>
    ),
  },
];

export default brdMap;
