import { createSelector } from "reselect";

export const currentLanguageSelect = createSelector(
  (state) => state.app,
  (app) => app.currentLanguage
);

export const currentModeCategorie = createSelector(
  (state) => state.app,
  (app) => app.modeCategorie
);
export const currentBlackList = createSelector(
  (state) => state.app,
  (app) => app.blackList
);
export const currentQuizze = createSelector(
  (state) => state.app,
  (app) => app.quizze
);
export const currentQuestions = createSelector(
  (state) => state.app,
  (app) => app.questions
);

export const currentElementOfRank = createSelector(
  (state) => state.app,
  (app) => app.elementOfRank
);

export const currentUserStateSelect = createSelector(
  (state) => state.app,
  (app) => app.currentUser
);

export const listStateSelect = createSelector(
  (state) => state.home,
  (home) => home?.list
);

export const detailsStateSelect = createSelector(
  (state) => state.home,
  (home) => home?.details
);

export const createStateSelect = createSelector(
  (state) => state.home,
  (home) => home?.create
);
