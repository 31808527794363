import React, { useState, useMemo, useEffect } from "react";
import { useUpdateQuizze } from "modules/Home/hooks/useUpdateQuizze";
import { Row, Col, Label, Button, Spinner, FormGroup } from "reactstrap";
import AuthNavbar from "components/Navbars/TopNavbar";
import AppInput from "components/AppInput";
import CONFIG from "config/apiConfig";
import CustomAsyncSelect from "components/CustomAsyncSelect";
import { Api } from "api";
//notifications
import { NotificationManager } from "react-notifications";

import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

const Details = (props) => {
  const { BASE_URL } = CONFIG;
  const { current } = props.details;

  const [{ fetching, error }, updateQuizze] = useUpdateQuizze(current?._id);

  const [state, setState] = useState({
    $q: "",
  });
  ///
  const dispatch = useDispatch();
  const lastQuestion = props.questions.slice(0, props.elementOfRank)[
    props.questions.slice(0, props.elementOfRank)?.length - 1
  ];

  const onUpdateData = async () => {
    const newListQuestion = [];
    try {
      const rep = await Api().post(`/user/quizzes/${props.quizze?.id}/answer`, {
        answer: state?.text?.label,
      });
      if (rep?.ok) {
        props.questions.map((item) => {
          if (item?.id === lastQuestion.id) {
            newListQuestion.push({
              ...item,
              response: state.text?.label,
              status: rep?.ok,
            });
          } else {
            newListQuestion.push({ ...item });
          }
        });
        props.actions.setQuestions(newListQuestion);
        setState({ ...state, text: "" });
      }
    } catch (error) {
      props.questions.map((item) => {
        if (item?.id === lastQuestion.id) {
          newListQuestion.push({
            ...item,
            response: state.text?.label,
            status: false,
          });
        } else {
          newListQuestion.push({ ...item });
        }
      });
      props.actions.setQuestions(newListQuestion);
      setState({ ...state, text: "" });
      props.actions.setElementOfRank(props.elementOfRank + 1);
      // NotificationManager.error(
      //   error?.message ||
      //     (error?.result?.length > 0 &&
      //       error.result.map((item) => item?.message)),
      //   false,
      //   10000
      // );
    }
  };
  // get response of inccorect response
  const [reponse, setReponse] = useState("");
  useEffect(() => {
    const fetchReponse = async () => {
      if (
        props.questions?.length === props.elementOfRank - 1 &&
        !lastQuestion?.status
      ) {
        try {
          const rep = await Api().get(
            `/user/quizzes/${props.quizze?.id}/reponse`
          );
          setReponse(rep?.reponse || "");
        } catch (error) {
          // NotificationManager.error(
          //   error?.message ||
          //     (error?.result?.length > 0 &&
          //       error.result.map((item) => item?.message)),
          //   false,
          //   10000
          // );
        }
      }
    };
    fetchReponse();
  }, [props.elementOfRank]);

  // Set Category to Black list
  const setToBlackList = () => {
    const NewBlackList = props.blackList;
    NewBlackList.push({ id: props.details?.id, date: new Date() });
    props.actions.setBlackList(NewBlackList);
    props.actions.viderState();
    dispatch(push("/home/list"));
  };

  console.log(
    "props.elementOfRank > 1",
    props.elementOfRank > 1,
    props.elementOfRank
  );

  return (
    <div className="app-main">
      <AuthNavbar
        currentLanguage={props.currentLanguage}
        modeCategorie={props.modeCategorie}
        hideMenu={true}
        // displayLogout={() => setShowLogout(true)}
      />
      <div
        className="backgroundImageGlobal"
        style={{
          backgroundImage: `url(${require("assets/img/bgImage.png")})`,
        }}
      ></div>
      <div className="containerHome">
        <div className="questionDetails">
          {/* {lastQuestion?.image && (
            <div className="imageQuestion">
              <img
                width={"175px"}
                src={`${BASE_URL}/api/v1/files/${lastQuestion?.image}/view`}
              />
            </div>
          )} */}
          <div className="quizzeHeader">
            {/* <img width={"175px"} src={require("assets/img/iconQuizz.png")} /> */}
            {lastQuestion?.image && (
              <div className="mx-4 ">
                <img
                  style={{
                    height: "120px",
                  }}
                  height="auto"
                  // src={`${BASE_URL}/api/v1/files/${lastQuestion?.image}/view`}
                  src={require("assets/img/iconQuizz.png")}
                />
              </div>
            )}
            <div>
              <div className="titleQuizze">{props.quizze?.title}</div>
              <div className="descQuizz">
                {props.quizze?.description || "-"}
              </div>
            </div>
          </div>
          <div className="countQuizz mt-2">
            <span>
              {" "}
              <img
                width={"35px"}
                src={require("assets/img/iconFleche.png")}
              />{" "}
            </span>
            <pan>
              {`You have up to ${
                props.questions?.length || 0
              } questions to answer to beat this quizz`}
            </pan>
            <span>
              <img
                className="ml-2"
                width={"25px"}
                src={require("assets/img/iconImoji.png")}
              />
            </span>
          </div>
          {props.questions?.length > 0 &&
            props.questions.slice(0, props.elementOfRank).map((item, i) => (
              <>
                <div className="question my-4">
                  <div className="keyQuestion">Question {i + 1}</div>
                  <div
                    className={
                      item?.response ? "textQuestion2" : "textQuestion"
                    }
                  >
                    {item?.text} <br />
                    {item?.image && (
                      <img
                        className="mt-2"
                        width={"100px"}
                        src={`${BASE_URL}/api/v1/files/${item?.image}/view`}
                      />
                    )}
                    <br />{" "}
                    {item?.status ? (
                      <div className="responseSuccess">
                        {/* Reponse Success for question */}
                        {/* {item?.response}{" "}
                        <span>
                          <img
                            width={"12px"}
                            alt="..."
                            src={require("assets/img/iconSuccess.png")}
                          />
                        </span>{" "} */}
                      </div>
                    ) : (
                      item?.response && (
                        <div className="responseFailed mt-2">
                          <img
                            width={"20px"}
                            alt="..."
                            src={require("assets/img/iconImoji2.png")}
                          />
                          <div className="ml-2">
                            Your answer : « {item?.response} » is incorrect
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </>
            ))}
          {props.questions?.length === props.elementOfRank - 1 &&
          !lastQuestion?.status ? (
            <>
              <div className="gameOver">
                <div>
                  <img
                    width={"58px"}
                    height={"100%"}
                    alt="..."
                    src={require("assets/img/iconGameOver.png")}
                  />
                </div>
                <div className="textGameOver ml-4">
                  <div>GAME OVER</div>
                  <div
                    className="mt-1"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div>
                      <img
                        width={"25px"}
                        height={"25px"}
                        alt="..."
                        src={require("assets/img/iconChecked.png")}
                      />
                    </div>
                    <div
                      className="ml-2"
                      style={{
                        color: "#1CB705",
                        fontSize: "13px",
                        lineHeight: "16px",
                      }}
                    >
                      THE CORRECT ANSWER IS: <br />
                      <span>{reponse}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                onClick={() => setToBlackList()}
                className="playAnother mt-4"
              >{`PLAY ANOTHER QUIZZ`}</div>
            </>
          ) : lastQuestion?.status ? (
            <>
              <div className="gameWiner">
                <div>
                  <img
                    className="iconWiner"
                    alt="..."
                    src={require("assets/img/iconWiner.png")}
                  />
                </div>
                <div className="textGameWiner ml-4">
                  <div
                    style={{
                      color: "#1CB705",
                      lineHeight: "16px",
                    }}
                  >
                    YOU’RE RIGHT !
                  </div>
                  <div>THE CORRECT ANSWER IS:</div>
                  <div>
                    <img
                      width={"25px"}
                      height={"25px"}
                      alt="..."
                      src={require("assets/img/iconChecked.png")}
                    />{" "}
                    <span className="ml-2">{lastQuestion?.response}</span>
                  </div>
                </div>
              </div>
              <div
                onClick={() => setToBlackList()}
                className="playAnother mt-4"
              >{`PLAY ANOTHER QUIZZ`}</div>
            </>
          ) : (
            <div className="responseQuestion">
              {/* <AppInput
                  placeholder="Enter your answer"
                  value={state.text?.label}
                  onChange={(t) => setState({ ...state, text: t })}
                /> */}

              <CustomAsyncSelect
                placeholder="Type your answer"
                required
                label=""
                inputSearch={true}
                type="quizz"
                url={`/user/quizzes/allanswers/?$filter={"language":${
                  props.currentLanguage?.value
                    ? props.currentLanguage?.value
                    : undefined
                } , "mode":${
                  props.modeCategorie ? "easy" : "hard"
                }}&&$category=${props.details?.id}`}
                onSelectValue={(c) => setState({ ...state, text: c, skip: 0 })}
                onInputChange={(c) => setState({ ...state, $q: c })}
                name="name"
                id="list"
                isClearable
                value={state.text}
                isClear={(c) => setState({ ...state, text: null })}
                // className="filter-input"
              />

              <div
                onClick={() => (state.text?.label ? onUpdateData() : null)}
                className={
                  state.text?.label
                    ? "btnResponse ml-2"
                    : "btnResponseDisabled ml-2"
                }
              >
                Answer
              </div>
            </div>
          )}
        </div>
        {error && <h6 className="auth-error">{error?.result?.message}</h6>}
      </div>
    </div>
  );
};

export default Details;
